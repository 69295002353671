// Default variables to override can be found here: https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
@import "~antd/dist/antd.less";

// This is a fix for an annoying bug where the light theme horizontal menu doesn't overflow correctly due to the use of margin instead of padding
.ant-menu-light.ant-menu-horizontal .ant-menu-item {
  margin: 0 !important;
  padding: 0 20px !important;
}

// Round the edges of the modals and toasts
.ant-message-notice-content,
.ant-modal-content {
  border-radius: 20px !important;
  overflow: hidden;
}

// Tweaks to sider to ensure logo is always placed at bottom
.ant-layout-sider {
  .ant-layout-sider-children {
    .sider-internal {
      display: flex;
      flex-direction: column;
      height: 100vh;
      overflow: hidden;
      overflow-y: auto;
    }
  }
}

// Tweaks for our custom image uploader
.custom-uploader {
  display: block;

  .ant-upload {
    display: block;
  }
}

// This is to fix a bug where including an icon on a menu-item wraps the a in a span and the color doesn't get matched correct
.ant-menu-dark .ant-menu-item {
  span,
  a {
    color: rgba(255, 255, 255, 0.65);
  }

  &.ant-menu-item-selected {
    span,
    a {
      color: #fff;
    }

    .anticon {
      color: #fff;
    }
  }

  &:hover {
    a {
      color: #fff !important;
    }
  }

  .anticon {
    color: #92278f;
  }
}

.rich-text-editor {
  .ant-form-item-control-input-content {
    width: 100%;
  }

  img,
  iframe {
    max-width: 100%;
  }
}

.ripple-body {
  img,
  iframe {
    max-width: 100%;
  }
}
